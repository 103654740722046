// @ts-nocheck
// map from UX graphVersions to the backend schema status
export const SCHEMA_STATUSES_MAP = new Proxy(
  {
    APPROVED: 'APPROVED',
    ARCHIVED: 'ARCHIVED',
    DRAFT: 'NEW',
    NEW: 'NEW',
  },
  {
    // ensure we're always comparing properly
    get: (target, propName) => {
      const key = propName.toUpperCase();
      return key in target ? target?.[key] : key;
    },
  }
);

export const getGraphVersionKey = (graphVersion) => SCHEMA_STATUSES_MAP[graphVersion];
export const makeSchemaKey = ({ entityId, graphVersion }) => `${entityId}-${getGraphVersionKey(graphVersion)}`;
